import { Dispatch, forwardRef, RefObject, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, Collapsible, Ui } from "common/components/atoms";
import { ChevronDownIcon, PieChartIcon, SettingsIcon, UserIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { handleLogout } from "common/utils/functions";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ContextContent.module.scss";
import SingleCompanyItem from "./SingleCompanyItem";

const t = createTranslation(TranslationNS.common, "components.header.userContext");

type ContextContentProps = {
  open: boolean;
  mobile?: boolean;
  isHeaderExpanded?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const subMenuAnimateDropDown = {
  enter: {
    opacity: 1,
    rotateX: 0,
    transition: {
      duration: 0.5,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    rotateX: -15,
    transition: {
      duration: 0.5,
      delay: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const subMenuAnimateSlide = {
  enter: {
    y: 0,
    opacity: 1,
    display: "block",
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 0.5,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const DEFAULT_COMPANIES_LIST = 5;
const COLLAPSIBLE_TEST_ID = "collapsible-data-container";

function isCollapsibleMenuOpen() {
  const collapsibleId = document.getElementById(COLLAPSIBLE_TEST_ID);
  const accordionID = document.querySelector(".accordion-collapse");

  if (collapsibleId?.contains(accordionID)) {
    return accordionID?.classList.contains("show");
  }

  return false;
}

const ContextContent = forwardRef<HTMLDivElement, ContextContentProps>(
  ({ open, mobile, isHeaderExpanded, setIsOpen }, ref) => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const collapsibleHeaderRef = useRef<HTMLDivElement>(null);

    const user = useStoreState((state) => state.account.user);
    const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
    const setAroOnboarding = useStoreActions((actions) => actions.companyOnboardingModel.setAroOnboarding);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const companies = useMemo(() => {
      if (user?.companies) {
        return user?.companies.sort((a, b) => Number(b.id === companyId) - Number(a.id === companyId));
      }
    }, [companyId, user?.companies]);

    const AccountRoutes = useMemo(
      () => [
        {
          route: getPath(["myPortfolio", "portfolio"]),
          title: t("routes.portfolio"),
          icon: <PieChartIcon />,
        },
        {
          route: getPath(["user", "general"]),
          title: t("routes.profile"),
          icon: <UserIcon />,
        },
        {
          route: getPath(["user", "account"]),
          title: t("routes.accountAndSecurity"),
          icon: <SettingsIcon />,
        },
      ],
      []
    );

    const logoutHandler = () => {
      handleLogout(instance);
    };

    const checkCollapsibleMenuOpen = () => {
      if (isCollapsibleMenuOpen()) {
        collapsibleHeaderRef?.current?.click();
      }
    };

    const onSingleCompanyPress = useCallback(() => {
      setIsOpen(false);
      setAroOnboarding({
        isActive: false,
      });
      const headerRef = ref as RefObject<HTMLDivElement>;

      if (isHeaderExpanded && headerRef.current) {
        headerRef?.current?.click();
      }

      checkCollapsibleMenuOpen();
    }, [isHeaderExpanded, ref, setAroOnboarding, setIsOpen]);

    const RenderHead = useCallback(
      ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
        return (
          <div className={classes["collapsible"]} ref={collapsibleHeaderRef} onClick={onClick}>
            <div className="d-flex align-items-center">
              <Ui.xs className={classes["title"]}>{activeEventKey ? "View less" : "View all"}</Ui.xs>

              <div className={classes["companies-counter"]}>
                <Ui.xs>{companies?.length}</Ui.xs>
              </div>
            </div>

            <ChevronDownIcon
              fontSize={24}
              className={classes["chevron"]}
              direction={activeEventKey ? "top" : "bottom"}
            />
          </div>
        );
      },
      [companies?.length]
    );

    return (
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            key="context-modal"
            initial="exit"
            variants={mobile ? subMenuAnimateSlide : subMenuAnimateDropDown}
            className={classes["context-menu"]}
            animate={open ? "enter" : "exit"}
          >
            <div className={classes["head"]}>
              {user?.profileImage ? (
                <Image alt="profile" src={user.profileImage} className={classes["image"]} />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="black"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
              )}
              <div className={classNames(classes["info"], "text-truncate")}>
                <Ui.xs bold className="text-truncate mb-1">
                  {user?.firstName} {user?.lastName}
                </Ui.xs>

                <Ui.xs className="text-truncate">{user?.email}</Ui.xs>
              </div>
            </div>

            {/* TODO: remove this after designs will be updated */}
            {!mobile && (
              <div className={classes["account-section"]}>
                {AccountRoutes.map(({ route, icon, title }, index) => (
                  <Link
                    to={route}
                    className={classes["link"]}
                    key={`Key for head account context route is: ${index}`}
                    onClick={() => {
                      setAroOnboarding({
                        isActive: false,
                      });
                      checkCollapsibleMenuOpen();
                      setIsOpen(false);
                    }}
                  >
                    {icon} <Ui.xs className="ms-2">{title}</Ui.xs>
                  </Link>
                ))}
              </div>
            )}

            {/* TODO: remove this after designs will be updated */}
            {!mobile && (
              <div className={classes["companies-section"]}>
                <div className="px-2 mt-2 mb-1 d-flex align-items-center justify-content-between">
                  <Ui.xs italic className={classes["title"]}>
                    {t("yourCompanies")}
                  </Ui.xs>
                  <Button
                    variant="tertiary"
                    className={classNames("ui-xs underline", classes["add-button"])}
                    onClick={() => {
                      setAroOnboarding({
                        isActive: false,
                      });

                      navigate(getPath(["onboard", "company", "setup"]));
                    }}
                  >
                    {t("add")} +
                  </Button>
                </div>

                <div className="position-relative">
                  {companies &&
                    R.take(DEFAULT_COMPANIES_LIST, companies).map((el, index) => (
                      <SingleCompanyItem
                        key={index}
                        company={el}
                        disabled={isLoading}
                        onClick={onSingleCompanyPress}
                        setIsLoading={setIsLoading}
                      />
                    ))}

                  {isLoading ? (
                    <div className={classes["loader-container"]}>
                      <Spinner className={classes["loader"]} animation="border" />
                    </div>
                  ) : null}
                </div>

                {(companies?.length || 0) > DEFAULT_COMPANIES_LIST ? (
                  <Collapsible reverse Header={RenderHead} id="collapsible-data-container">
                    <>
                      {companies &&
                        R.takeLast(companies.length - DEFAULT_COMPANIES_LIST, companies).map((el, index) => (
                          <SingleCompanyItem
                            key={index}
                            company={el}
                            setIsLoading={setIsLoading}
                            onClick={onSingleCompanyPress}
                          />
                        ))}
                    </>
                  </Collapsible>
                ) : null}
              </div>
            )}

            <div className={classes["logout"]} onClick={logoutHandler}>
              <Ui.xs className="logOutSelenium">{t("logOut")}</Ui.xs>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

ContextContent.displayName = "Header-ContextContent";

export default ContextContent;
